import { minValue, maxValue, requiredIf } from "@/validation/validators";

export const getAnimatedWidgetSettings = (
    scopeSelectedItem,
    selectedOrganizationId,
    selectedLocationId
) => ({
    settings: {
        height: { minValue: minValue(300) },
        width: { minValue: minValue(200) },
        reviews: { minValuen: minValue(1), maxValue: maxValue(20) },
        organization: {
            required: requiredIf(
                () => scopeSelectedItem === "organization" && selectedOrganizationId === null
            ),
        },
        location: {
            required: requiredIf(
                () => scopeSelectedItem === "location" && selectedLocationId === null
            ),
        },
    },
});
