<template>
    <GenericPage
        class="review_settings"
        :headerProps="{
            title:
                widget.name === 'animated-dark'
                    ? $t('cp__customise_widgets__animated_dark')
                    : $t('cp__customise_widgets__animated_light'),
            subtitle: $t('cp__customise_widgets__animated_light_subtitle'),
            backButtonTo: { path: '/widgets' },
        }"
    >
        <SettingsForm class="settings_form">
            <SettingsBlock
                class="settings_block"
                :title="$t('cp__customise_widgets__settings_title')"
            >
                <SettingsSection class="customise_widgets_fieldset" label="">
                    <label class="subtitle3">
                        {{ $t("cp__customise_widgets__settings_organization") }}
                    </label>
                    <label class="body2">
                        <span>
                            {{ $t("cp__customise_widgets__settings_organization_subtitle") }}
                        </span>
                    </label>

                    <RadioList
                        class="organization_options"
                        required
                        :options="scopeSelectionList"
                        v-model="scopeSelectedItem"
                    />

                    <Dropdown
                        class="organizations"
                        :placeholder="$t('cp__customise_widgets__settings_location_placeholder')"
                        v-if="scopeSelectedItem === 'organization' && organizations.length"
                        :options="organizations"
                        v-model="selectedOrganizationId"
                        enableExternalValidation
                        :externalValidationError="$v.settings.organization.$error"
                        :externalValidationErrorMessage="
                            resolveErrorMessage($v.settings.organization)
                        "
                    />

                    <Dropdown
                        class="location"
                        :placeholder="$t('cp__customise_widgets__settings_location_placeholder')"
                        v-if="scopeSelectedItem === 'location' && locations.length"
                        :options="locations"
                        v-model="selectedLocationId"
                        enableExternalValidation
                        :externalValidationError="$v.settings.location.$error"
                        :externalValidationErrorMessage="resolveErrorMessage($v.settings.location)"
                    />

                    <label class="subtitle3">
                        {{ $t("cp__customise_widgets__settings_style") }}
                    </label>
                    <label class="body2">
                        <span>
                            {{ $t("cp__customise_widgets__settings_style_subtitle") }}
                        </span>
                    </label>
                    <div class="size_input_container">
                        <TextInput
                            :label="$t('cp__customise_widgets__settings_width')"
                            v-model="settings.width"
                            numeric
                            :min="200"
                            class="size_input"
                        />
                        <TextInput
                            :label="$t('cp__customise_widgets__settings_height')"
                            v-model="settings.height"
                            numeric
                            :min="300"
                            class="size_input"
                        />
                    </div>

                    <label class="subtitle3">
                        {{ $t("cp__customise_widgets__settings_reviews") }}
                    </label>
                    <label class="body2">
                        <span>
                            {{ $t("cp__customise_widgets__settings_reviews_subtitle") }}
                        </span>
                    </label>
                    <TextInput
                        v-model="settings.reviews"
                        numeric
                        :min="1"
                        :max="20"
                        class="reviews_input"
                    />

                    <label class="body2">
                        <span>
                            {{ $t("cp__customise_widgets__settings_reviews_timeframe") }}
                        </span>
                    </label>
                    <RadioList
                        class="timeframe_options"
                        v-model="settings.limitLastYear"
                        required
                        :options="[
                            {
                                value: 0,
                                displayValue: $t(
                                    'cp__customise_widgets__settings_reviews_timeframe_recent'
                                ),
                            },
                            {
                                value: 1,
                                displayValue: $t(
                                    'cp__customise_widgets__settings_reviews_timeframe_12_months'
                                ),
                            },
                        ]"
                    />
                </SettingsSection>
            </SettingsBlock>

            <SettingsBlock
                class="settings_block"
                :title="$t('cp__customise_widgets__preview_title')"
            >
                <div v-if="$v.$error">
                    <h2 class="body1">
                        {{ $t("cp__customise_widgets__preview_error") }}
                    </h2>
                </div>
                <div v-else class="widget_preview">
                    <iframe
                        title="content"
                        v-if="!showEmbedCode"
                        frameborder="0"
                        id="tv-widget-iframe"
                        scrolling="no"
                        marginheight="0"
                        marginwidth="0"
                        :src="src"
                        :width="settings.width"
                        :height="settings.height"
                    ></iframe>
                    <WidgetEmbedCode
                        class="widget_embed_code"
                        v-else
                        :embedCode="embedCode"
                        @close="close"
                    />
                </div>
            </SettingsBlock>
        </SettingsForm>

        <SettingsFooter>
            <Button class="cancel_button" buttonStyle="white" @click.native="cancel">
                {{ $t("cp__generic__cancel") }}
            </Button>
            <Button
                :disabled="$v.$error"
                :loading="widgetEmbedCodeIsLoading"
                @click.native="copyCode"
            >
                {{ $t(copyButtonLabel) }}
            </Button>
        </SettingsFooter>
    </GenericPage>
</template>

<script>
import RadioList from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/Inputs/RadioList.vue";
import TextInput from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/Inputs/TextInput.vue";
import Button from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/Button.vue";
import Dropdown from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/Inputs/Dropdown.vue";
import * as copy from "copy-to-clipboard";
import { mapGetters } from "vuex";
import { resolveErrorMessage, validationMixin } from "@/validation";
import GenericPage from "@/components/Global/Molecules/GenericPage.vue";
import SettingsBlock from "@/components/Settings/Molecules/SettingsBlock.vue";
import SettingsSection from "@/components/Settings/Molecules/SettingsSection.vue";
import SettingsForm from "@/components/Settings/Molecules/SettingsForm.vue";
import SettingsFooter from "@/components/Settings/Molecules/SettingsFooter.vue";
import { getAnimatedWidgetSettings } from "@/validation/schemas/animatedWidgetsSettings";
import generateWidget from "@/graphql/widgets/generateWidget.gql";
import WidgetEmbedCode from "@/components/Widgets/Atoms/WidgetEmbedCode.vue";

export default {
    name: "GeneralSettings",
    mixins: [validationMixin],
    components: {
        GenericPage,
        SettingsBlock,
        SettingsSection,
        SettingsForm,
        SettingsFooter,
        TextInput,
        Button,
        RadioList,
        Dropdown,
        WidgetEmbedCode,
    },
    data() {
        return {
            widget: {},
            locations: [],
            organizations: [],
            widgetEmbedCodeIsLoading: false,
            showEmbedCode: false,
            embedCode: null,
            copyButtonLabel: "customer_portal_widgets_showcase_embed_code_copy",
            settings: {
                type: "animated",
                theme: "",
                resultScope: "",
                scopeValue: "",
                width: 300,
                height: 400,
                reviews: 10,
                limitLastYear: 0,
            },
            scopeSelectionList: [],
            scopeSelectedItem: "",
            selectedOrganizationId: null,
            selectedLocationId: null,
        };
    },
    validations() {
        return getAnimatedWidgetSettings(
            this.scopeSelectedItem,
            this.selectedOrganizationId,
            this.selectedLocationId
        );
    },
    computed: {
        ...mapGetters({
            currentLanguage: "LanguageStore/getLanguage",
        }),
        selectedScopeId() {
            switch (this.scopeSelectedItem) {
                case "holding":
                    return this.widget.options.scopeData.holding.id;
                case "organization":
                    return this.selectedOrganizationId;
                case "location":
                    return this.selectedLocationId;
                default:
                    return "";
            }
        },
        selectedScope() {
            if (this.scopeSelectedItem === "holding") {
                return this.widget.options.scopeData.holding?.id
                    ? `&holdingID=${this.widget.options.scopeData.holding.id}`
                    : "";
            }
            if (this.scopeSelectedItem === "organization") {
                return this.selectedOrganizationId != null
                    ? `&organisatieID=${this.selectedOrganizationId}`
                    : "";
            }
            if (this.scopeSelectedItem === "location") {
                return this.selectedLocationId != null
                    ? `&vestigingID=${this.selectedLocationId}`
                    : "";
            }
            return "";
        },
        src() {
            return `${this.widget.options.baseUrl}/cgi-bin/react_tool.pl?Op=Widget&template=reviews_${this.settings.theme}${this.selectedScope}&width=${this.settings.width}&height=${this.settings.height}&num_reviews=${this.settings.reviews}&limit_last_year=${this.settings.limitLastYear}`;
        },
    },
    watch: {
        settings: {
            deep: true,
            handler() {
                this.copyButtonLabel = "customer_portal_widgets_showcase_embed_code_copy";
            },
        },
    },
    methods: {
        resolveErrorMessage,
        async copyCode() {
            await this.generateWidget();

            if (!this.embedCode) return;

            this.showEmbedCode = true;
            await copy(this.embedCode);
            this.selectEmbedCode();
            await this.$store.dispatch("pushNotification", {
                type: "success",
                title: this.$t("cp__general__copied"),
                message: this.$t("cp__general__copied__message"),
            });

            this.copyButtonLabel = "customer_portal_widgets_showcase_embed_code_copied";
        },
        selectEmbedCode() {
            const selection = window.getSelection();
            selection.removeAllRanges();
            const range = document.createRange();
            range.selectNodeContents(document.getElementById("widget"));
            selection.addRange(range);
        },
        async generateWidget() {
            this.widgetEmbedCodeIsLoading = true;
            try {
                this.settings.scopeValue = this.selectedScopeId;
                this.settings.resultScope = this.scopeSelectedItem;
                const { data } = await this.$apollo.mutate({
                    mutation: generateWidget,
                    variables: {
                        input: this.settings,
                    },
                });

                // atob is the browser-native base64 decode operation
                this.embedCode = atob(data.generateWidget.embedCode);
            } catch (errors) {
                await this.$store.dispatch("pushNotification", {
                    type: "error",
                    title: this.$t("cp__pn__config_invitation__error__title"),
                    message: this.$t("cp__generic__error"),
                });
            } finally {
                this.widgetEmbedCodeIsLoading = false;
            }
        },
        close() {
            this.showEmbedCode = false;
            this.copyButtonLabel = "customer_portal_widgets_showcase_embed_code_copy";
        },
        cancel() {
            this.$router.push("/widgets");
        },
        setLocations() {
            this.locations = this.widget.options.scopeData.locations.map((loc) => ({
                value: loc.id,
                displayValue: loc.name,
            }));
            if (this.widget.options.scopeData.organisations.length > 0) {
                this.selectedLocationId = this.widget.options.scopeData.locations[0].id;
            } else {
                this.selectedLocationId = this.widget.options.scopeData.locations.id;
            }
        },
        setOrganisations() {
            this.organizations = this.widget.options.scopeData.organisations.map((loc) => ({
                value: loc.id,
                displayValue: loc.name,
            }));
            if (this.widget.options.scopeData.organisations.length > 1) {
                this.selectedOrganizationId = this.widget.options.scopeData.organisations[0].id;
            } else {
                this.selectedOrganizationId = this.widget.options.scopeData.organisation.id;
            }
        },
    },
    created() {
        this.widget = this.$route.params.widget;
        // eslint-disable-next-line prefer-destructuring
        this.settings.theme = this.widget.id.split("-")[1];
        this.settings.resultScope = this.widget.options.scopeData.resultScope;

        this.setLocations();
        this.setOrganisations();

        if (this.settings.resultScope === "holding") {
            const hasHolding = this.widget.options.scopeData.holding !== null;
            const label = hasHolding
                ? this.widget.options.scopeData.holding.name
                : this.$t("INTEGRATION_APP_PUBLICATION_WIDGET_DEFAULT_HOLDING_NAME");
            this.scopeSelectionList.push({ value: "holding", displayValue: label });
        }

        if (
            (this.widget.options.scopeData.organisation?.id ||
                this.widget.options.scopeData.organisations.length > 0) &&
            (this.settings.resultScope === "holding" ||
                this.settings.resultScope === "organization")
        ) {
            const hasOrganization = this.widget.options.scopeData.organisation !== null;
            const label = hasOrganization
                ? this.widget.options.scopeData.organisation.name
                : this.$t("INTEGRATION_APP_PUBLICATION_WIDGET_DEFAULT_ORGANIZATION_NAME");
            this.scopeSelectionList.push({ value: "organization", displayValue: label });
        }

        if (
            this.widget.options.scopeData.location?.id ||
            this.widget.options.scopeData.locations.length
        ) {
            if (this.widget.options.scopeData.locations.length > 1) {
                const label = this.$t("INTEGRATION_APP_PUBLICATION_WIDGET_DEFAULT_LOCATION_NAME");
                this.scopeSelectionList.push({ value: "location", displayValue: label });
            } else {
                this.scopeSelectionList.push({
                    value: "location",
                    displayValue: this.widget.options.scopeData.location.name,
                });
            }
        }

        // Mark default item.
        this.scopeSelectedItem = this.settings.resultScope;

        this.$v.$touch();
    },
    beforeRouteEnter(to, from, next) {
        if (to.params.widget === undefined) {
            next("/widgets");
        } else {
            next();
        }
    },
};
</script>

<style lang="scss" scoped>
@import "@/style_variables/style_variables.scss";
@import "~include-media";

.settings_form {
    width: 100%;
    max-width: 950px;
}

.settings_block {
    width: 100%;
    margin-top: 40px;

    &:first-of-type {
        margin-top: 40px;
    }

    &:last-of-type {
        margin-bottom: 170px;
    }
}

.settings_fieldset * {
    display: inline-block;
    margin-bottom: 16px;
}

.customise_widgets_fieldset * {
    display: inline-block;
    margin-bottom: 8px;
}

.size_input_container {
    display: flex;
    margin-bottom: 30px;
}
.size_input {
    width: 194px;
    &:first-of-type {
        margin-right: 12px;
    }
}
.reviews_input {
    width: 94px;
}
.timeframe_options,
.organization_options {
    display: grid;
}
.location {
    width: 100%;
    margin-bottom: 30px;
    margin-left: 30px;
    max-width: 370px;
}
.widget_showcase_display {
    margin-top: 30px;
}
.cancel_button {
    margin-right: 12px;
}
.widget_embed_code {
    max-width: 700px;
}
.widget_preview {
    margin-top: 30px;
}
</style>
